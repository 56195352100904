import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import getPlanIcon from '../../utils/getPlanIcon';
import Tooltip from '../../generic/Tooltip';
import QAIcon from '../../icons/QAIcon';
export default function () {
    function repeatIcon1(globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading, icon, iconIndex) {
        return React.createElement('i', {
            'aria-label': plan.name + ' plan icon',
            'className': getPlanIcon(plan.name),
            'key': iconIndex,
            'role': 'presentation'
        });
    }
    function scopePlanName2(globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading) {
        var planName = plan.name.toLowerCase();
        return React.createElement('span', {
            'className': 'price',
            'key': '4067'
        }, '\n          $', this.getDiscountedPrice(plan), '\n          ', planName === 'yearly' || planName === 'annual' ? ['/Yr\n          '] : null, planName === 'monthly' || planName === 'apprentice' ? ['/Mo\n          '] : null);
    }
    function scopePlanName3(globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading) {
        var planName = plan.name.toLowerCase();
        return React.createElement('span', {
            'className': 'price-before',
            'key': '4487'
        }, '\n          $', plan.price, '\n          ', planName === 'yearly' || planName === 'annual' ? ['/Yr'] : null, planName === 'monthly' || planName === 'apprentice' ? ['/Mo'] : null);
    }
    function scopeSaving4(globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading) {
        var saving = (plan.price - plan.firstMonthPrice).toFixed(2);
        return React.createElement('span', {
            'className': 'upgrade-plan',
            'key': '7141'
        }, 'Save $', saving, ' on your first payment');
    }
    function onClick5(globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading, showButton) {
        this.props.selectPlan(plan);
    }
    function onClick6(globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading, showButton) {
        this.props.selectPlan(plan);
    }
    function onClick7(globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading, showButton) {
        this.props.selectPlan(plan);
    }
    function scopeShowButton8(globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading) {
        var showButton = (this.props.currentPlan || !this.props.upgrading) && this.props.currentPlan !== plan.rank && plan.rank > 0 && this.props.currentPlan < 3;
        return React.createElement('div', {
            'className': 'row' + ' ' + _.transform({ 'simple-plan': plan.rank < 3 }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'key': '7599'
        }, !isGlobalPromoEnabled ? [
            plan.rank > this.props.currentPlan && this.props.upgrading && showButton && plan.rank < 4 ? React.createElement('button', {
                'aria-label': (this.props.isSubscribed ? 'Upgrade' : 'Subscribe') + ' to ' + plan.name + ' plan',
                'className': 'btn -thin -border-white',
                'onClick': this.props.upgrading ? () => this.props.upgradeAction(this.props.plans, plan, this.props.currentPlan) : () => this.props.selectPlan(plan),
                'disabled': this.props.loading > 0,
                'key': '80281'
            }, this.props.isSubscribed ? 'upgrade' : 'subscribe') : null,
            !this.props.upgrading && showButton && plan.rank < 4 && this.props.usedFreeTrial ? React.createElement('button', {
                'aria-label': 'Subscribe to ' + plan.name + ' plan',
                'className': 'btn -thin -border-white',
                'onClick': onClick5.bind(this, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading, showButton),
                'key': '80283'
            }, 'Subscribe') : null,
            !this.props.upgrading && showButton && plan.rank < 4 && !this.props.usedFreeTrial ? React.createElement('button', {
                'aria-label': 'Subscribe to ' + plan.name + ' plan',
                'className': 'btn -thin -border-white',
                'onClick': onClick6.bind(this, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading, showButton),
                'key': '80285'
            }, '\n            7-Day Free Trial\n          ') : null
        ] : null, isGlobalPromoEnabled ? [!this.props.upgrading && showButton && plan.rank < 4 && !this.props.usedFreeTrial ? React.createElement('button', {
                'aria-label': 'Subscribe to ' + plan.name + ' plan',
                'className': 'btn -thin -border-white',
                'onClick': onClick7.bind(this, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading, showButton),
                'key': '93001'
            }, '\n            Subscribe & save ', globalPromo.discount, globalPromo.discountType == 'pctg' ? ['%'] : null) : null] : null, plan.rank > 3 ? React.createElement('a', {
            'aria-label': 'Learn more about group plans',
            'className': 'btn -thin -border-white link-btn',
            'href': 'https://pages.stanwinstonschool.com/group-subscription-info-request-v2-swsca',
            'key': '9800'
        }, 'Learn more') : null);
    }
    function scopeIsProratedLoading9(globalPromo, isGlobalPromoEnabled, plan, planIndex) {
        var isProrated = !(_.size(_.filter(this.props.plans, p => p.firstMonthPrice !== null)) === 0);
        var loading = this.props.loading;
        return React.createElement('div', {
            'key': plan.id,
            'className': 'col plan' + ' ' + _.transform({
                'group-plan': plan.rank === 4,
                'no-group-plan': this.props.changeSubscription && plan.rank === 4
            }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('div', {
            'className': 'row header ' + (this.props.currentPlan === plan.rank ? 'current' : ''),
            'onClick': plan.name === 'Group Learning' ? () => window.location.href = 'https://pages.stanwinstonschool.com/group-subscription-info-request-v2-swsca' : this.props.currentPlan === plan.rank ? null : this.props.upgrading ? () => this.props.upgradeAction(this.props.plans, plan, this.props.currentPlan) : () => this.props.selectPlan(plan)
        }, React.createElement.apply(this, [
            'div',
            { 'className': 'icons-container' },
            _.map(new Array(plan.rank > 3 ? 3 : 1), repeatIcon1.bind(this, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading))
        ]), React.createElement('span', { 'className': 'plan-name' }, plan.name), plan.rank > 3 ? React.createElement('span', {
            'className': 'price-empty',
            'key': '4003'
        }) : null, plan.rank < 4 ? scopePlanName2.apply(this, [
            globalPromo,
            isGlobalPromoEnabled,
            plan,
            planIndex,
            isProrated,
            loading
        ]) : null, isGlobalPromoEnabled && plan.rank < 4 ? scopePlanName3.apply(this, [
            globalPromo,
            isGlobalPromoEnabled,
            plan,
            planIndex,
            isProrated,
            loading
        ]) : null, !isGlobalPromoEnabled && (plan.name === 'Yearly' || plan.name === 'annual') ? React.createElement('span', {
            'className': 'special-description',
            'key': '4897'
        }, '\n          Save $320 per year (compared with the Monthly plan)\n        ') : null, plan.name === 'Group Learning' ? React.createElement('span', {
            'className': 'special-description',
            'key': '5131'
        }, '\n          Significant savings!\n        ') : null), React.createElement('div', { 'className': 'row main-description' }, '\n        ', this.htmlParser(plan.description1), '\n      '), isGlobalPromoEnabled ? React.createElement('div', {
            'className': 'row',
            'key': '5450'
        }, React.createElement('span', {}, React.createElement('i', {
            'aria-label': 'Check icon',
            'className': 'icon-checkmark',
            'role': 'presentation'
        }))) : null, React.createElement('div', { 'className': 'row' }, React.createElement('i', {
            'aria-label': 'Check icon',
            'className': 'icon-checkmark',
            'role': 'presentation'
        })), React.createElement('div', {
            'className': 'row' + ' ' + _.transform({ 'last-row': isGlobalPromoEnabled }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, isGlobalPromoEnabled && plan.rank === 3 ? React.createElement('i', {
            'aria-label': 'Check icon',
            'className': 'icon-checkmark',
            'role': 'presentation',
            'key': '6011'
        }) : null, !isGlobalPromoEnabled ? React.createElement('i', {
            'aria-label': 'Check icon',
            'className': 'icon-checkmark',
            'role': 'presentation',
            'key': '6139'
        }) : null, isGlobalPromoEnabled && (plan.rank === 2 || plan.rank === 4) ? React.createElement('span', { 'key': '6249' }, 'No') : null), !isGlobalPromoEnabled ? React.createElement('div', {
            'className': 'row',
            'key': '6390'
        }, plan.rank === 1 || plan.rank === 2 ? [
            '\n          ',
            plan.description2,
            '\n        '
        ] : null, plan.rank === 3 ? React.createElement('span', { 'key': '6564' }, '\n          FREE\n        ') : null, plan.rank === 4 ? [React.createElement('i', {
                'aria-label': 'Check icon',
                'className': 'icon-checkmark',
                'role': 'presentation',
                'key': '66341'
            })] : null) : null, !isGlobalPromoEnabled ? React.createElement('div', {
            'className': _.transform({ 'last-row': !isGlobalPromoEnabled }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ') + ' ' + 'row',
            'key': '6798'
        }, '\n        ', plan.rank === 3 ? plan.description2 : 'No', '\n      ') : null, this.props.upgrading && (plan.rank > this.props.currentPlan && plan.firstMonthPrice && isProrated || this.props.currentPlan === plan.rank) ? React.createElement('div', {
            'className': 'row',
            'key': '6966'
        }, plan.rank > this.props.currentPlan && plan.firstMonthPrice && isProrated && plan.price - plan.firstMonthPrice > 0 ? scopeSaving4.apply(this, [
            globalPromo,
            isGlobalPromoEnabled,
            plan,
            planIndex,
            isProrated,
            loading
        ]) : null, this.props.currentPlan === plan.rank ? React.createElement('span', {
            'className': 'current-plan',
            'key': '7455'
        }, 'current plan') : null) : null, (this.props.currentPlan !== 3 || plan.rank < 3 || !this.props.upgrading) && plan.firstMonthPrice || this.props.currentPlan !== plan.rank ? scopeShowButton8.apply(this, [
            globalPromo,
            isGlobalPromoEnabled,
            plan,
            planIndex,
            isProrated,
            loading
        ]) : null);
    }
    function repeatPlan10(globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated, loading) {
        return scopeIsProratedLoading9.apply(this, [
            globalPromo,
            isGlobalPromoEnabled,
            plan,
            planIndex
        ]);
    }
    function scopeGlobalPromoIsGlobalPromoEnabled11() {
        var globalPromo = this.props.globalPromo;
        var isGlobalPromoEnabled = this.props.isGlobalPromoEnabled;
        return React.createElement.apply(this, [
            'section',
            {
                'id': 'SubscriptionPlanList',
                'key': '258'
            },
            React.createElement('div', { 'className': 'col large' }, React.createElement('div', { 'className': 'row header' }, React.createElement('h3', {}, 'What you get')), React.createElement('div', { 'className': 'row' }, React.createElement('i', {
                'aria-label': 'Online Course Library icon',
                'className': 'icon-play',
                'role': 'presentation'
            }), React.createElement('span', { 'className': 'large-description' }, '\n        Online Course library\n      '), React.createElement(Tooltip, { 'message': 'Explore 1000+ hours of on-demand instruction by the world\'s finest artists & technical wizards' })), React.createElement('div', { 'className': 'row' }, React.createElement('img', {
                'alt': 'Access to pathways icon',
                'className': 'icon-learning-path',
                'src': '/global/assets/images/icons/learningIcon.svg'
            }), React.createElement('span', { 'className': 'large-description' }, '\n        Access to Pathways\n      '), React.createElement(Tooltip, { 'message': 'Enjoy the freedom of online education with the guidance of a traditional curriculum' })), React.createElement('div', { 'className': 'row' }, React.createElement('i', {
                'aria-label': 'Watch on your computer, phone, or tablet icon',
                'className': 'icon-mobilepluspc',
                'role': 'presentation'
            }), React.createElement('span', { 'className': 'large-description' }, '\n        Watch on your computer, phone, or tablet\n      '), React.createElement(Tooltip, { 'message': 'Learn on your own time and at your own pace from your computer, phone, or tablet' })), !isGlobalPromoEnabled ? React.createElement('div', {
                'className': 'row',
                'key': '1704'
            }, React.createElement('i', {
                'aria-label': 'Live Course Discounts icon',
                'className': 'icon-discountb-1',
                'role': 'presentation'
            }), React.createElement('span', { 'className': 'large-description' }, '\n        Live Course Discounts\n      '), React.createElement(Tooltip, { 'message': 'Subscribers are eligible for discounted enrollment in our interactive live online courses' })) : null, React.createElement('div', { 'className': 'row last-row' }, React.createElement('i', {
                'aria-label': 'Discounts icon',
                'className': 'icon-discount',
                'role': 'presentation'
            }), React.createElement('span', { 'className': 'large-description' }, '\n        Discounts on DVD, Stream Purchases & Merchandise\n      ')), !this.props.upgrading ? React.createElement('div', {
                'className': 'row tagline',
                'key': '2382'
            }, !isGlobalPromoEnabled ? React.createElement('span', {
                'className': 'large-description bottom-tagline',
                'key': '2465'
            }, '\n        Select a plan and start your ', this.props.currentPlanName, '\n        ', !this.props.usedFreeTrial ? React.createElement('span', {
                'className': 'accent',
                'key': '2617'
            }, '7-Day Free Trial') : null, this.props.usedFreeTrial ? React.createElement('span', {
                'className': 'accent',
                'key': '2704'
            }, 'Subscription') : null) : null) : null),
            _.map(this.props.plans.filter(this.filterSubscriptionPlans), repeatPlan10.bind(this, globalPromo, isGlobalPromoEnabled))
        ]);
    }
    return !_.isEmpty(this.props.plans) ? scopeGlobalPromoIsGlobalPromoEnabled11.apply(this, []) : null;
}